import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/new/content'
import { graphql, Link } from 'gatsby'
import Spacer from 'components/new/spacer'
import Button from 'components/button/button'

const PartsOnSitePage = props => {
  const {
    data: { heroImage },
  } = props
  return (
    <Layout>
      <Helmet>
        <title>Parts OnSite | Hutson Inc</title>
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Parts',
                'item': 'https://www.hutsoninc.com/parts/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Parts OnSite',
                'item': 'https://www.hutsoninc.com/parts/parts-onsite/',
              },
            ],
          })}
        </script>
      </Helmet>

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='Parts OnSite'
        overlayOpacity={0.3}
      />

      <Content kind='full'>
        <H2>Reduce downtime with Parts OnSite</H2>
        <P>
          With Parts OnSite, you can have the parts you need in your very own shop. The program is
          simple—we’ll work with you to determine the best parts inventory for your operation. Then,
          you stock the parts at your place in one of our heavy-duty metal cabinets. Whenever you
          need a part, just grab it from the cabinet and get back to work! Have a John Deere
          Multi-Use Account? Ask about our No Payments/No Interest programs.
        </P>
        <Spacer size='m' />
        <H3>How does it work?</H3>
        <P>
          We will work with you to get the right assortment of parts for your equipment during each
          season. Common stocking parts may include filters, belts, grease and other common wear
          parts. Need to replenish your stock? Reordering is simple! Place restocking orders at{' '}
          <Link to='/locations/'>your local Hutson store</Link> or through the{' '}
          <Link to='/hutson-customer-portal'>Hutson Customer Portal</Link>. We can even set up a
          periodic inventory check to help make sure you stay stocked with what you need. We like to
          think of the Parts OnSite program as a common-sense approach to saving time and money. No
          matter how close your local Hutson location is, your shop is a lot closer.
        </P>
        <Spacer />
        <H2>Let's get started</H2>
        <P>
          Want to know more about Parts OnSite? Get in touch with the parts department at your
          nearest Hutson location!
        </P>
        <Spacer size='s' />
        <Button as={ButtonLink} to='/locations/' ghost color='green'>
          Find your nearest location
        </Button>
      </Content>
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

const ButtonLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "parts/parts-onsite-hero.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default PartsOnSitePage
